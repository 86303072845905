export default {
    // [TODO] remove when not more used in application
    order: {
        create: {
            back: 'Back'
        }
    },
    nav: {
        administration: {
            label: 'Administration',
            tripsOverview: 'Trips',
            dossierEntry: 'Dossier Entry',
            dossier: 'Dossiers',
            entity: 'Entities',
            customerGroups: 'Customer Groups',
            document: 'Documents'
        },
    },
    customer: {
        overview: {
            showTrips: 'Show trips',
            downloadButton: 'Download Twinfield export',
        },
        edit: {
            invoicingLocationTitle: 'Invoicing address',
            visitingLocationTitle: 'Visiting address',
            contactsTitle: 'Contacts (counts)',
            paymentItem: 'Payment Item',
        },
        field: {
            contacts: {
                label: 'Contacts'
            },
            contact: {
                value: {
                    dayshift: 'Dayshift',
                    invoice: 'Invoice',
                    nightshift: 'Nightshift',
                    administration: 'Administration',
                    general: 'General',
                    operation: 'Operation'
                }

            },
            entity: {
                label: 'Entity'
            },
            customerGroup: {
                label: 'Customer group'
            },
            group: {
                label: 'Group',
            },
            groupNumber: {
                label: 'Group Number',
            },
            groupName: {
                label: 'Group Name',
            },
            selfBilling: {
                label: 'Self-billing',
                option: {
                    yes: 'Yes',
                    no: 'No'
                }
            },
            forceAutofinalizeActivities: {
                label: 'Can Auto finalize',
                option: {
                    yes: 'Yes',
                    no: 'No'
                }
            },
        },
        contact: {
            field: {
                addContact: {
                    label: 'Add contact'
                },
                generalContact: {
                    label: 'General contact'
                },
                documentsInvoices: {
                    label: 'Documents & Invoices'
                },
                customerUpdate: {
                    label: 'Customer Update'
                },
                customerCustoms: {
                    label: 'Customer Customs'
                },
                generalName: {
                    name: 'General name',
                    email: 'General "To" email address',

                },
                documentsInvoicesName: {
                    name: 'Documents & Invoices "To" name',
                    email: 'Doc. & Invoices "To" e-mail address',
                },
                customerUpdateName: {
                    name: 'Customer Update "To" name',
                    email: 'Cust. Update "To" email address',
                },
                customerCustomName: {
                    name: 'Customer Customs "To" name',
                    email: 'Cust. Customs "To" email address',
                },
            },
            title: 'Email configurations'
        },
        trip: {
            overview: {
                customerName: 'Cust. name',
                customerReference: 'Cust. ref.',
                loadingPlace: 'Loading Place',
                loadingDate: 'Loading date',
                unloadingPlace: 'Unloading place',
                unloadingDate: 'Unloading date',
                statusIn: 'Status in:',
            },
            statuses: {
                new: 'New',
                quoted: 'Quoted',
                accepted: 'Accepted',
                rejected: 'Rejected',
                planned: 'Planned',
                completed: 'Completed',
                draft: 'Draft',
                sent: 'Sent',
                in_progress: 'In Progress',
                invoiced: 'Invoiced',
                canceled: 'Canceled',
                error: 'Error'
            },
            create: {
                title: 'Add Customer',
            },
            edit: {
                title: 'Edit Customer',
            },
            delete: {
                title: 'Delete Customer',
            },
            field: {
                name: {
                    label: 'Name',
                    placeholder: 'Fill in a name',
                },
                id: {
                    label: 'Trip no.',
                },
                customer: {
                    label: 'Customer',
                },
                salesPrice: {
                    label: 'Price',
                },
                reference: {
                    label: 'Reference',
                },
                created_at: {
                    label: 'Created at',
                },
                updated_at: {
                    label: 'Updated at',
                },
                status: {
                    label: 'Status',
                }
            },
        },
        locations: {
            title: 'Loading / unloading addresses',
            button: {
                addAddress: 'Add address'
            },
            field: {
                name: {
                    label: 'Company'
                },
                address: {
                    label: 'Address'
                },
                street: {
                    label: 'Street'
                },
                houseNumber: {
                    label: 'Number'
                },
                zipCode: {
                    label: 'Zip Code'
                },
                country: {
                    label: 'Country'
                },
                city: {
                    label: 'City',
                },
                code: {
                    label: 'Code',
                }
            },
        },
        tab: {
            contact: 'Contacts',
            locations: 'Address book',
            routes: 'Trips & pricing'
        }
    },
    trip: {
        label: 'Trip',
        modal: {
            bulkForward: {
                header: 'Forward trip(s) to',
                saveAndSend: 'Save & Send'
            },
            completeTrip: {
                header: 'Trip completion',
                completeButton: 'Complete trip'
            },
            cancelTrip: {
                header: 'Trip cancel',
                description: 'Are you sure you want to cancel the trip?',
                cancelButton: 'Confirm',
            },
            reactivateTrip: {
                header: 'Trip reactivate',
                description: 'Are you sure you want to reactivate the trip?',
            },
            creditLimitReached: {
                header: 'Credit limit reached',
                description: 'Please note that with this sales price amount, the customer will exceed its credit limit.',
                okButton: 'Ok',
                createInvoiceButton: 'Create Invoice',
                cancelTripButton: 'Cancel Trip',
            },
            subcontract: {
                header: 'Subcontract trip {{tripId}}',
                subcontractor: 'Subcontractor',
                cargo: 'Cargo description',
                transportTrip: 'Transport trip',
                pricing: 'Pricing',
                salesPrice: 'Sales price in Euro',
                purchasePrice: 'Purchase price in Euro',
                marginBrutto: 'Brutto margin',
                marginPercent: 'Percent margin',
                truckLicensePlate: 'Truck license plate',
                trailerLicensePlate: 'Trailer license plate',
                driverName: 'Driver name',
            },
            cancel: {
                header: 'Cancel trip',
                description: 'Are you sure that you want to cancel this trip?',
                confirmButton: 'Cancel trip',
                cancelButton: 'Close',
                reason: 'Reason (optional)',
                editDescription: 'Are you sure that you want to cancel this trip?\n The trip will be unplanned and no longer active in the system.\n If this is the only trip in a dossier then its dossier will also be canceled.',
                success: 'The trip has been canceled',
            },
            uncancel: {
                header: 'Uncancel trip',
                description: 'Are you sure that you want to uncancel this trip?',
                confirmButton: 'Uncancel trip',
                cancelButton: 'Close',
                editDescription: 'Are you sure that you want to uncancel this trip?',
                success: 'The trip has been uncanceled',
            }
        },
        buttons: {
            completeTrip: 'Complete trip',
            cancelTrip: 'Cancel trip',
            reactivateTrip: 'Reactivate trip',
            createInvoice: 'Create invoice',
            editInvoice: 'Edit invoice',
            subcontractTripNew: 'New subcontract trip',
            subcontractTripEdit: 'Edit subcontracted trip',
            subcontract: 'Subcontract',
            collapseAll: 'Collapse all',
        },
        bulk: {
            trip: 'Trip',
            tableHeader: {
                price: 'Price',
                status: 'Status',
                type: 'Type',
                reference: 'Reference',
                company: 'Company',
                location: 'Location',
                dateFrom: 'Date from',
                timeUntil: 'Time until',
                instructions: 'Instructions',
            },
            bulkAction: 'Bulk action',
            forwardTripsTo: 'Forward trips to',
            createShipment: 'Create shipment',
        },
        overview: {
            title: 'Trips overview',
            customerName: 'Cust. name',
            customerReference: 'Cust. ref.',
            invoiceReference: 'Ref.',
            invoiceStatus: 'Inv. status',
            loadingPlace: 'Loading Place',
            loadingDate: 'Loading date',
            unloadingPlace: 'Unloading place',
            unloadingDate: 'Unloading date',
            statusIn: 'Status in:',
            status: 'Status',
            subcontracted: 'Subcontracted (Sc)',
            proofOfDelivery: 'POD',
            fleetNumbers: 'Trailer no.',
            truckFleetNumbers: 'Truck no.',
            truckLicensePlate: 'Truck license plate',
            truckLicensePlateOwner: 'Truck license own',
            truckLicensePlateSc: 'Truck license sc',
            driver:{
                remarks: 'Driver remarks'
            },
            invoiced: {
                label: 'Invoiced',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No'
                }
            },
            hasProofOfDelivery: {
                label: 'Has POD',
                shortLabel: 'POD',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No',
                },
            },
        },
        create: {
            title: 'Add trip',
            bulkTitle: 'Trip entry',
            back: 'Back',
        },
        createBulk: {
            title: 'Add bulk trip',
            bulkTitle: 'Bulk trip ID',
            back: 'Back',
        },
        edit: {
            title: 'Edit trip {{id}}',
            goodCardTitle: 'Goods',
            activityCardTitleLoad: 'Shipper',
            subcontracted: 'Subcontracted in: ',
            activityCardTitleUnload: 'Consignee',
            addAddress: 'Add address',
            showJobs: 'Show activities',
            shipmentDetails: {
                activitiesGeneration: 'Activities will be generated based on provided Jobs'
            },
            segments: {
                trip_details: 'Trip details',
                original_job: 'Original activity',
                shipment_details: 'Shipment details',
                cargolines: 'Cargolines',
                customs: 'Customs',
                ferry_booking: 'Ferry booking',
                documents: 'Documents',
                invoice: 'Invoice',
                trip_history: 'Trip history',
                emptyBox: 'Empty box',
                trips: 'Trips'
            }
        },
        delete: {
            title: 'Delete Trip',
        },
        field: {
            invoice: {
                label: 'Invoice',
            },
            tripNumber: {
                label: 'Trip No.'
            },
            id: {
                label: 'Trip no.',
            },
            status: {
                label: 'Status',
            },
            name: {
                label: 'Name',
            },
            customer: {
                label: 'Customer',
                placeholder: 'Customer',
            },
            documents: {
                label: 'Upload documents',
            },
            podDocument: {
                label: 'Add Proof of Delivery',
            },
            invoiceReference: {
                label: 'Reference',
                labelShort: 'Ref.',
                placeholder: 'Reference',
            },
            subcontracted: {
                label: 'Subcontracted',
                shortLabel: 'Sc',
                options: {
                    all: 'All',
                    own: 'Own',
                    subcontracted: 'Sc',
                },
            },
            unit: {
                label: 'Unit',
                value: {
                    ftl: 'FTL',
                    ltl: 'LTL',
                    other: 'Other',
                },
            },
            currency: {
                label: 'Currency',
                value: {
                    euro: 'EUR',
                    pound: 'GBP',
                },
                code: {
                    euro: '€',
                    pound: '£',
                }
            },
            goodsDescription: {
                label: 'Goods Description',
                placeholder: 'General description about the goods',
            },
            cargoDescription: {
                label: 'Cargo Description',
                placeholder: 'General description about the goods',
            },
            reference: {
                label: 'Reference',
            },
            weight: {
                label: 'Weight',
                placeholder: 'Total weight in KG',
            },
            packagingAmount: {
                label: 'Quantity',
            },
            packagingType: {
                label: 'Type',
            },
            adr: {
                label: 'ADR',
                placeholder: '123456',
                value: {
                    true: 'Yes',
                    false: 'No',
                },
            },
            remarks: {
                label: 'Trip remarks',
                placeholder: 'General remarks about the trip',
                popup: 'Shown on invoice, per trip',
            },
            salesPrice: {
                label: 'Price',
                placeholder: '€0,00,-',
            },
            assignedUser: {
                label: 'Assigned to'
            },
            fuelSurcharge: {
                label: 'Fuel',
                popup: {
                    true: 'Fuel: included in the price',
                    false: 'Fuel: not included in the price'
                }
            },
            customerUpdate: {
                label: 'Contact customer update',
            },
            customerCustoms: {
                label: 'Contact customer customs',
            },
            createdAt: {
                label: 'Created at',
            },
            createdBy: {
                label: 'Created by',
            },
            BulkTripSummary: {
                label: 'Bulk trip summary',
                amountLabel: 'Amount',
                loadingDateLabel: 'Loading date',
                unloadingDateLabel: 'Unloading date'
            },
            destination: {
                label: 'Destination'
            },
            dossier: {
                label: 'Dossier',
                number: 'Dossier no.'
            },
            trailer: {
                label: 'Trailer nr.',
                agLabel: 'A+G Trailer',
                options: {
                    yes: 'Yes',
                    no: 'No'
                }
            },
            numberOfActivities: {
                label: '#Act.',
            },
            datetimeFrom: {
                label: 'Start'
            },
            datetimeUntil: {
                label: 'End'
            },
            forwarded: {
                label: 'Forwarded',
                options: {
                    yes: 'Yes',
                    no: 'No',
                }
            },
            charter: {
                label: 'Charter'
            },
            forwardingPurchasePrice: {
                label: 'Purchase Price'
            },
            forwardingRemarks: {
                label: 'Forwarding Remarks',
                placeholder: 'Forwarding related remarks'
            },
            forwardingStatus: {
                label: 'Forwarding Status',
                values: {
                    to_sell: 'To sell',
                    waiting_load: 'Waiting load',
                    waiting_pod: 'Waiting pod',
                    finished: 'Finished'
                }
            },
            cancelReason: {
                label: 'Cancel reason',
            },
        },
        infoPopup: {
            invoiceReferenceTitle: 'Reference',
            goodsDescriptionTitle: 'Goods description',
            lastUnloadPlaceTitle: 'Last unloading place',
            loadingFromTitle: 'Loading from',
            loadingToTitle: 'Loading to',
            finishedDateTitle: 'Finished at',
            plannedDepartureTitle: 'Planned departure',
            actualDepartureTitle: 'Actual departure',
            plannedArrivalTitle: 'Planned arrival',
            actualArrivalTitle: 'Actual arrival',
            activityFinishedAt: 'Activity finished at',
            activityFinalizedAt: 'Activity finalized at',
            openToFinalizeActivity: 'Open to finalize activity',
        },
        invoicing: {
            description: 'Transports according to appendix',
            from: {
                label: 'from',
            },
            to: {
                label: 'to',
            }
        },
        button: {
            cancel: 'Cancel trip',
            uncancel: 'Uncancel trip',
        },
    },
    geo: {
        notFound: 'No location found!',
        InvalidURI: 'Invalid geocoding url',
    },
    activity: {
        overview: {
            proofOfDelivery: 'POD'
        },
        saveAndFinalizeButton: 'Save & Finalize',
        skipValidation: 'Skip Validation',
        buttons: {
            finalize: 'Finalize activity',
            create: 'Add activity',
            undoFinish: 'Undo finish activity'
        },
        changeStatusModal: {
            header: 'Change activity status',
        },
        statuses: {
            draft: 'Draft',
            planned: 'Planned',
            eta: 'Driving',
            arrived: 'Arrived',
            waiting: 'Waiting',
            started: 'Started',
            finished: 'Finished',
            finalized: 'Finalized',
            executing: 'Executing',
            canceled: 'Canceled',
            new: 'New'
        },
        types: {
            abbreviations: {
                load: 'LO',
                unload: 'UL',
                driver_change: 'DC',
                driver_check_in: 'DI',
                driver_check_out: 'DO',
                truck_pick_up: 'TP',
                truck_drop: 'TD',
                truck_solo: 'TS',
                trailer_pick_up: 'TP',
                trailer_drop: 'TD',
                custom_activity: 'IA',
                customer_activity: 'CA',
                customer_group_allocation: 'GC',
                truck_refuel: 'TR',
                truck_service: 'TS',
                train_pick_up: 'TP',
                train_drop: 'TD',
                terminal_pick_up: 'TP',
                terminal_drop: 'TD',
                undefined: '?'
            },
            actions: {
                load: 'Loading',
                unload: 'Unloading',
                customerActivity: 'Customer activity',
                truckPickUp: 'Picking up a truck',
                truckDrop: 'Dropping truck',
                truckSolo: 'Driving truck solo',
                trailerPickUp: 'Picking up a Trailer',
                trailerDrop: 'Dropping trailer',
                driverChange: 'Changing driver',
                driverCheckIn: 'Checking driver in',
                driverCheckOut: 'Checking driver out',
                customActivity: 'Internal activity',
                customerGroupAllocation: 'Allocating customer group ',
            },
            load: 'Load',
            unload: 'Unload',
            driver_check_in: 'Driver check in',
            driverCheckIn: 'Driver check in',
            driver_change: 'Driver change',
            driverChange: 'Driver change',
            driver_check_out: 'Driver check out',
            driverCheckOut: 'Driver check out',
            truck_pick_up: 'Truck pick up',
            truckPickUp: 'Truck pick up',
            truck_drop: 'Truck drop',
            truckDrop: 'Truck drop',
            truck_solo: 'Truck solo',
            truckSolo: 'Truck solo',
            trailer_pick_up: 'Trailer pick up',
            trailerPickUp: 'Trailer pick up',
            trailer_drop: 'Trailer drop',
            trailerDrop: 'Trailer drop',
            custom_activity: 'Internal activity',
            customActivity: 'Internal activity',
            customer_activity: 'Customer activity',
            customerActivity: 'Customer activity',
            customer_group_allocation: 'Cus. group allocation',
            customerGroupAllocation: 'Cus. group allocation',
            truck_refuel: 'Truck refuel',
            truckRefuel: 'Truck refuel',
            truck_service: 'Truck service',
            truckService: 'Truck service',
            train_pick_up: 'Train pick up',
            trainPickUp: 'Train pick up',
            train_drop: 'Train drop',
            trainDrop: 'Train drop',
            terminal_pick_up: 'Terminal pick up',
            terminalPickUp: 'Terminal pick up',
            terminal_drop: 'Terminal drop',
            terminalDrop: 'Terminal drop',
            undefined: '- - - -'
        },
        field: {
            id: {
                label: 'ID'
            },
            bookingReference: {
                label: 'Booking Reference'
            },
            unitLicensePlate: {
                label: 'Unit License Plate'
            },
            cargoSpecification: {
                label: 'Cargo Specification'
            },
            unumber: {
                label: 'Un Number'
            },
            proofOfDelivery: {
                label: 'POD'
            },
            class19: {
                label: 'Class (1-9)'
            },
            packgroup: {
                label: 'Packgroup (1-3)'
            },
            lq: {
                label: 'LQ'
            },
            mrnNumber: {
                label: 'Mrn Number'
            },
            additionalInformation: {
                label: 'Additional Information'
            },
            goodsDescription: {
                label: 'Description'
            },
            remarks: {
                label: 'Remarks'
            },
            status: {
                label: 'Status'
            },
            statusDraftAt: { label: 'Draft At', },
            statusPlannedAt: { label: 'Planned At', },
            statusEtaAt: { label: 'Driving At', },
            statusArrivedAt: { label: 'Arrived At', },
            statusWaitingAt: { label: 'Waiting At', },
            statusStartedAt: { label: 'Started At', },
            statusFinishedAt: { label: 'Finished At', abbreviation: 'FA' },
            statusFinalizedAt: { label: 'Finalized At', },
            statusCanceledAt: { label: 'Canceled At', },
            type: {
                label: 'Type',
                value: {
                    load: 'Load',
                    unload: 'Unload',
                },
            },
            location: {
                label: 'Location',
                coordinates: 'Coordinates',
                city: 'City',
                address: 'Address',
                placeholder: 'Choose a location',
            },
            orderedArrivalDatetimeFrom: {
                start: 'Start',
                label: 'Loading Date From',
                labelByType: {
                    load: 'Loading Date From',
                    unload: 'Unloading Date From',
                }
            },
            orderedArrivalDatetimeUntil: {
                end: 'End',
                label: 'Loading Date Until',
                labelShort: 'Until',
                labelByType: {
                    load: 'Loading Date Until',
                    unload: 'Unloading Date Until',
                }
            },
            instructions: {
                label: 'Instructions',
                placeholder: 'Driver instructions',
                popup: 'Will be shared with driver on activity send',
            },
            companyName: {
                label: 'Company name'
            },
            asap: {
                label: 'ASAP',
            },
            time: {
                label: 'Time',
            },
            from: {
                label: 'From',
            },
            to: {
                label: 'To',
            },
            distance: {
                label: 'Distance',
            },
            date: {
                label: 'Date',
            },
        },
        modal: {
            title: {
                label: 'Activity Finalize'
            },
            notification: {
                missingStatusFinalizedAt: 'Fill activity date Finished At'
            }
        },
        create: {
            title: 'Add Activity'
        },
        customerLocation: {
            save: {
                on: 'Save company-address combination for customer: ON',
                off: 'Save company-address combination for customer: OFF',
                addressBook: 'Save to address book',
                nf: 'Location not found',
            },
            addressBookDuplicateError:{
                message: 'Found address book entry with the same address.'
            }
        },
        message: {
            dateSeparator: 'to',
            asap: 'ASAP',
            trailer: {
                label: 'Trailer',
            },
            coordinates: {
                label: 'GPS',
            }
        },
        undoFinishConfirmation: {
            text: 'Are you sure you want to undo finish activity',
            header: 'Undo finish activity',
            notification: 'Activity succesfully undo finished'
        }
    },
    dossier: {
        label: 'Dossier',
        overview: {
            title: 'Dossiers [{{count}}]',
            truck: {
                label: 'Truck(s)'
            },
            trailer: {
                label: 'Trailer(s)'
            },
            button: {
                linkToSelfBill: {
                    label: 'Bundle & link self-bill [{{count}}]',
                    multipleCustomersSelectedTooltip: 'Cannot bundle dossiers from multiple customers into single selfbill.',
                    linkedDossierSelectedTooltip: 'In the selection there are dossiers, that are already linked to selfbill.',
                    nonSelfbilledCustomerSelectedTooltip: 'In the selection there are dossiers, that belong to non-billable customers.',
                },
                edit: 'Edit dossier',
                duplicate: 'Duplicate dossier',
            },
            readyForInvoice: 'Ready for invoice',
        },
        edit: {
            title: 'Dossier: Nr.{{num}} W{{week}} {{cus}}',
            segments: {
                details: 'Dossier details',
                trips: 'Trips',
                documents: 'Documents'
            },
            canceled:{
                infoBlockHeader: 'Warning: This dossier is canceled',
                infoBlockDescription: 'Including trips will not show up in  the application (planning, executing, invoicing etc)'
            },
        },
        saveConfirmation: {
            header: 'Dossier contains executing/finished activities that has been edited',
            text: 'Are you sure you want to save?',
            cantSaveLabel: 'You must add at least one activity to save the dossier'
        },
        errorNotification: {
            weekNumber: 'The latest activity has the week number {{weekNumber}}, do you want to change it?',
            notEnoughActivities: 'Please add at least 2 activities with both location and date.',
            weekNumberDiffers: 'The first activity is in week {{firstActWnr}}. This differs from the dossier week number (w{{insertedWnr}}). Are you sure you want to continue?',
            weekNumberTooBig: 'Week number cannot be bigger that 53'
        },
        unsavedChangesConfirmation: 'You have unsaved changes. Are you sure you want to leave?',
        create: {
            title: 'Add Dossier',
            switchButton:{
                entryView: 'New dossier',
                editView: 'Existing dossier'
            }
        },
        field: {
            id: {
                label: 'Dossier No.'
            },
            dossierNumber: {
                label: 'Dossier number',
                shortLabel: 'Nr.'
            },
            customer: {
                label: 'Customer'
            },
            trips: {
                label: 'Trips'
            },
            weekNumber: {
                label: 'Week number',
                short: 'Week nr.',
                shortest: 'W',
            },
            remarks: {
                label: 'Internal dossier remarks',
                placeholder: 'General remarks about the dossier',
                popup: 'Not shared with customer or driver. Not shown on the invoice. Visible for other user\'s within the TMS',
            },
            createdAt: {
                label: 'Created At',
            },
            createdBy: {
                label: 'Created By',
            },
            tripsCount: {
                label: '#T',
            },
            invoiceReference: {
                label: 'Reference',
                shortLabel: 'Ref.',
                popup: 'Shown on invoice, per dossier',
            },
            route:{
                label: 'Trip'
            },
            cancelReason: {
                label: 'Cancel reason',
            },
            selfBill: {
                reference: {
                    label: 'Self-bill ref',
                },
            },
            datetimeFrom: {
                label: 'Date',
            },
            price: {
                label: 'Price',
            },
            status: {
                option: {
                    yes: 'Yes',
                    no: 'No',
                    both: 'Both',
                },
            },
            selfBilled: {
                label: 'Self-billed',
                options: {
                    yes: 'Yes',
                    no: 'No',
                    both: 'Both',
                },
            },
            truck: {
                label: 'Truck',
            },
            trailer: {
                label: 'Trailer',
            },
            driver: {
                label: 'Driver',
            },
            invoice: {
                label: 'Invoice',
            }
        },
        deleteButton:{
            label: 'Delete Dossier'
        },
        button: {
            previewSavePdf: 'Preview Pdf',
            sendEmail: 'Send Email',
            cancel: {
                label: 'Cancel trips & dossier',
                alreadyStarted: 'One trip has been already started',
            },
            uncancel: {
                label: 'Uncancel trips & dossier',
            },
        },
        modal: {
            cancel: {
                header: 'Cancel dossier',
                confirmButton: 'Cancel dossier',
                cancelButton: 'Close',
                reason: 'Reason (optional)',
                success: 'The dossier has been canceled',
                description: 'Are you sure that you want to cancel this dossier?',
            },
            uncancel: {
                header: 'Uncancel dossier',
                confirmButton: 'Uncancel dossier',
                cancelButton: 'Close',
                success: 'The dossier has been uncanceled',
                description: 'Are you sure that you want to uncancel this dossier?',
            }
        }
    },
    entity: {
        overview: {
            title: 'Entity overview',
            editButton: 'Edit Entity',
            addButton: 'Add Entity'
        },
        edit: {
            title: 'Edit entity',
        },
        create: {
            title: 'Add Entity',
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Entity name',
            },
            vat: {
                label: 'Vat',
            },
            customer: {
                label: 'Customer',
                count: '# Customers',
            },
            remarks: {
                label: 'Remarks',
                placeholder: 'General remarks about the entity',
            },
        }
    },
    customerGroup: {
        overview: {
            title: 'Customer group overview',
            addButton: 'Add Customer group'
        },
        edit: {
            title: 'Edit Customer group',
        },
        create: {
            title: 'Add Customer group',
        },
        field: {
            id: {
                label: 'Group No.'
            },
            name: {
                label: 'Group name',
            },
            groupNumber: {
                label: 'Group number',
            },
            color: {
                label: 'Color',
            },
            customer: {
                label: 'Customer'
            },
            remarks: {
                label: 'Remarks',
                placeholder: 'General remarks about the entity',
            },
        }
    },
    document: {
        overview: {
            title: 'Document overview',
            addButton: 'Add document',
            downloadButton: 'Download'
        },
        edit: {
            title: 'Edit document',
        },
        create: {
            title: 'Add document',
        },
        field: {
            id: {
                label: 'Document No.'
            },
            type: {
                label: 'File type',
            },
            file: {
                label: 'File',
            },
            path: {
                label: 'Path',
            },
        },
        filter: {
            noSubdirectories: 'Dossier files',
            allFiles: 'All files'
        },
        preview: {
            noFileToPreview: 'No file to preview',
            openFilePreview: 'Open file preview',
            closeFilePreview: 'Close file preview',
        },
        input: {
            upload: {
                label: 'Upload file',
            },
            download: {
                label: 'Download file'
            },
        }
    },
    invoiceLine: {
        field: {
            description: {
                linkedObjectTemplate: 'Doss. A+G {{dossierNumber}}, Date: {{date}}, From: {{from}}, To: {{to}}, Distance: {{distance}}'
            }
        }
    },
};

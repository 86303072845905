import { observable } from 'mobx';
import { getMoneyInputProps, t } from '@code-yellow/spider';
import { each, get } from 'lodash';

export const TAB_TITLE_PREFIX = 'A + G';
export const FRONTEND_API_BASE_URL = process.env.REACT_APP_CY_FRONTEND_API_BASE_URL || '/api/';

//TargetCompanyName debounce on search
export const DEBOUNCE_WAIT = 200;

// Config set by bootstrap.
export let MAPS_API_KEY = '';
export let MAPS_API_URL = '';
export const BUILD_INFO = observable({
    version: 'dev',
});

export function configOverride(bootstrap) {
    MAPS_API_KEY = bootstrap.google_maps_api_key;
    MAPS_API_URL = bootstrap.google_maps_api_url;
    Object.assign(BUILD_INFO, bootstrap.build_info);
}

// Stolen from re-cy-cle
// lodash's `camelCase` method removes dots from the string; this breaks mobx-spine
export function snakeToCamel(s) {
    if (s.startsWith('_')) {
        return s;
    }
    return s.replace(/_\w/g, m => m[1].toUpperCase());
}

// lodash's `snakeCase` method removes dots from the string; this breaks mobx-spine
export function camelToSnake(s) {
    return s.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

// TODO: make separate helper files categorized by theme, e.g. "money" and "date"
// This is insane at the moment, sorry man.

export const PUBLIC_URL =
    process.env.NODE_ENV !== 'production' ? process.env.PUBLIC_URL : '';

// While in debug mode, customer ids can be filtered here. It speeds up page
// loading and is automatically disabled on production to prevent goldplated ids
// going live.
export const ALLOCATION_IDS = [569, 290]; //[410, 414];

export const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// Also used by mobile, which has a window, but no location?
export const IS_STAGE = typeof window !== 'undefined' && /staging|stage/.test(window.location.href);
export const IS_UAT = typeof window !== 'undefined' && window.location.href.includes('uat');

// Feature flags
export const FLAG_ACTIVITY_ISSUES = IS_DEBUG || IS_STAGE;

export function floatToDecimal(value) {
    return value.toFixed(2).replace('.', ',');
}

// Stolen from https://gist.github.com/penguinboy/762197#gistcomment-2380871
const flatten = (object, prefix = '') => {
    return Object.keys(object).reduce((prev, element) => {
        return typeof object[element] === 'object'
            ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
            : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
    }, {});
}

/**
 * Get list of error messages from the backend response. Typical usage:
 *
 * model.save().catch(response =>
 *     parseBackendErrorMessages(response.response.data.errors)
 * )
 */
export function parseBackendErrorMessages(errors: object) {
    const messages: string[] = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.message')) {
            messages.push(flat[key]);
        }
    });

    return messages;
}

export function parseBackendErrorCodes(errors: object) {
    const codes: string[] = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.code')) {
            codes.push(flat[key]);
        }
    });

    return codes;
}

export function decimalToFloat(value) {
    if (typeof value !== 'string') {
        return null;
    }
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export const SCREEN_WIDTH_PX = '1280px';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATETIME_FORMAT = 'yyyy-LL-dd\'T\'HH:mm:ssZZZ';
export const DATE_FORMAT = 'dd-MM-yyyy';
export const DATE_FORMAT_SHORT = 'dd-MM';
export const DATE_FORMAT_SHORT_SLASH = 'dd/MM';
export const DATE_FORMAT_SHORT_FULL = 'dd-MM-yy';
export const DATETIME_FORMAT = 'dd-MM-yyyy HH:mm';
export const DATETIME_FORMAT_PRETTY = 'ccc, LLL d, HH:mm';
export const DATETIME_SEC_FORMAT = 'dd-MM-yyyy HH:mm:ss';
export const DATETIME_FORMAT_SHORT = 'dd-MM HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_RANGE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const ACTION_DELAY = 300;

export function setMoneyForBackend(value, decimals=2) {
    if (typeof value !== 'string') {
        return 0;
    }

    const parsed = decimalToFloat(value);

    if (!parsed) {
        return 0;
    }

    return isFinite(parsed) ? parsed * Math.pow(10, decimals) : 0;
}

export function format(date, format, defaultFormat = '') {
    if (date) {
        return date.toFormat(format);
    }

    return defaultFormat;
}

const moneyFormat = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol'
});

export function formatMoney(value, decimals = 2) {
    // Better money formatter, which prefixed the euro symbol. We're not yet
    // ready for this...
    //
    // There is a small but important difference with how MoneyInput formats
    // negative numbers. The negative sign must come first, and
    // Intl.NumberFormat sets the negative sign after the € sign.
    const formatted = moneyFormat.format(value / Math.pow(10, decimals)).split(' ').join('');

    if (formatted.includes('-')) {
        return formatted[1] + formatted[0] + formatted.slice(2);
    }

    return formatted;
}

export function getMoneyForUser(value, decimals=2) {
    if (typeof value !== 'number') {
        return null;
    }

    return (value / Math.pow(10, decimals)).toFixed(decimals).replace('.', ',');

}

// Found it weird to use money helpers on fuel surcharge, so simply
// wrap them.
export function setFuelSurchargeForBackend(value) {
    return setMoneyForBackend(value);
}

export function getFuelSurchargeForUser(value) {
    return getMoneyForUser(value);
}

export function setFactorForBackend(value) {
    return setMoneyForBackend(value);
}

export function getFactorForUser(value) {
    return getMoneyForUser(value);
}

export function getFactorInputProps() {
    return Object.assign(getMoneyInputProps(), {
        prefix: undefined,
        suffix: '%',
    });
}

// It is possible that in a <select> component, the currently selected model
// is not present in the list; either it is deleted, or the store has pagination, etc.
export function addSelectedModelInOptions(models, selectedModel) {
    const newModels = models.filter();
    if (selectedModel.id && !newModels.find(m => m.id === selectedModel.id)) {
        newModels.push(selectedModel);
    }
    return newModels;
}

// Accepts a request error, and transforms it into an array
// of notification messages.
export function formatCustomValidationErrors(err) {
    let output = [];

    each(get(err, 'response.data.errors'), (errors, resource) => {
        output = output.concat(
            errors.map((e, i) => {
                return {
                    key: `${resource}${i}`,
                    message: e.message,
                    dismissAfter: 4000,
                };
            })
        );
    });
    return output;
}

export const BOOL_OPTIONS = [
    { value: 'true', text: t('form.yes') },
    { value: undefined, text: t('form.either') },
    { value: 'false', text: t('form.no') },
];

export function getObjectConstructor<T extends object>(instance: T){
    // Why its so hard to access static properties from the instance in typescript?
    // https://github.com/microsoft/TypeScript/issues/3841
    return instance.constructor as unknown as T;
}

/**
 * Turns URL (object) into a proper file URL
 *
 * Function copied from Spider where it's unfortunately not exported.
 */
export function getFileUrl(url) {
    if (url === null || typeof url === 'string') {
        return url;
    } else {
        return `${url.preview || URL.createObjectURL(url)}?content_type=${url.type}&filename=${url.name}`;
    }
}

/**
 * Calculates the opaque RGB color of a partially transparent color if overlayed against a white background
 *
 * @param hexColor the color of which to compute the opaque color if overlayed over a white background with the given opacity
 * @param opacity the opacity of the color overlayed over white
 * @returns CSS rgb() color
 */
export function calculateColorWithOpacity(hexColor: string, opacity: number) {
    // Convert hex to RGB
    const hex = hexColor.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Calculate the color with opacity
    const resultR = Math.round((1 - opacity) * 255 + opacity * r);
    const resultG = Math.round((1 - opacity) * 255 + opacity * g);
    const resultB = Math.round((1 - opacity) * 255 + opacity * b);

    // Return in CSS rgb() notation (but without explicit rgb() cast so it can be used with rgba)
    return `${resultR}, ${resultG}, ${resultB}`;
}


/**
 * Convert hex string to RGB in CSS notation
 *
 * @param hex hex string, formatted with # at the front
 * @returns CSS rgb() color
 */
export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    // Return in CSS rgb() notation (but without explicit rgb() cast so it can be used with rgba)
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
}

export const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/**
 * Serialize URL parameters, specifically serialize arrays as comma-separated lists
 * in contrary to the 'querystring' package which repeats the variable.
 *
 * @param params object
 * @returns URL search params
 */
export function serializeParams(params) {
    const urlSearchParams = new URLSearchParams();

    for (const key in params) {
      if (Array.isArray(params[key])) {
        urlSearchParams.append(key, params[key].join(','));
      } else {
        urlSearchParams.append(key, params[key]);
      }
    }

    return urlSearchParams.toString();
}

